/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint-disable-next-line import/no-extraneous-dependencies
import { RJSFSchema } from '@rjsf/utils';

const showOn: any = {
  type: 'array',
  title: 'Show On Device',
  items: {
    type: 'string',
    enum: ['ALL', 'DESKTOP', 'TABLET', 'MOBILE', 'NONE'],
  },
};

const inputStyles: any = {
  type: 'object',
  title: 'Input styles',
  format: 'CustomContainer',
  properties: {
    color: {
      type: ['null', 'string'],
      title: 'Text color',
      format: 'CustomColorPicker',
      default: null,
    },
    backgroundColor: {
      type: ['null', 'string'],
      title: 'Background color',
      format: 'CustomColorPicker',
      default: null,
    },
    borderColor: {
      type: ['null', 'string'],
      title: 'Border color',
      format: 'CustomColorPicker',
      default: null,
    },
    borderStyle: {
      type: ['null', 'string'],
      title: 'Border style',
      default: null,
    },
  },
};

const socialPlatformOption: any = {
  order: {
    type: 'number',
    title: 'Order',
    default: 10,
  },
  showOn,
  icon: {
    type: 'string',
    default: '',
  },
  default: {
    type: 'boolean',
    title: 'default',
  },
  multiShare: {
    type: 'boolean',
    title: 'Allow multishare',
    default: false,
  },
  preview: {
    type: 'object',
    title: 'Preview Options',
    properties: {
      title: {
        type: 'string',
        title: 'Title',
      },
      description: {
        type: 'string',
        title: 'Description',
      },
      link: {
        type: 'string',
        title: 'Link',
      },
      titleAfterShare: {
        type: 'string',
        title: 'Title after share',
      },
      descriptionAfterShare: {
        type: 'string',
        title: 'Description after share',
      },
      linkAfterShare: {
        type: 'string',
        title: 'Link after share',
      },
      showPostImage: {
        type: 'boolean',
        title: 'Show the post image preview on the social option selection',
        default: false,
      },
    },
  },
  shareButtonText: {
    type: 'string',
    title: 'Share Button text',
  },
  afterShareButtonText: {
    type: 'string',
    title: 'After Share Button text',
  },
};

const schema: RJSFSchema = {
  title: 'Asset',
  type: 'object',
  format: 'CustomContainer',
  properties: {
    content: {
      type: 'object',
      title: 'Data Content',
      properties: {
        userInfoSubmitButtonText: {
          title: 'Submit button text',
          type: 'string',
          default: 'Get XX% off',
        },
        userInfoFirstNameInputPlaceholder: {
          title: 'First Name input placeholder',
          type: 'string',
          default: 'Your first name',
        },
        userInfoEmailInputPlaceholder: {
          title: 'Email input placeholder',
          type: 'string',
          default: 'Your email address',
        },
        enhancedLogs: {
          type: 'boolean',
          title: 'Enhanced Logs',
          default: false,
        },
        mobile: {
          type: 'object',
          title: 'Mobile',
          properties: {
            userInfoCaptureStep: {
              type: 'object',
              title: 'User info capture step',
              properties: {
                title: {
                  title: 'Title',
                  type: 'string',
                  default: 'Get XX% off for you and your friends',
                },
                subtitle: {
                  title: 'Subtitle',
                  type: ['null', 'string'],
                  default:
                    'Enter your email to share now and receive your voucher code',
                },
                extraParagraphs: {
                  title: 'Extra Paragraphs',
                  type: ['null', 'array'],
                  items: {
                    type: 'string',
                  },
                },
              },
            },
            shareStep: {
              type: 'object',
              title: 'Share step',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title',
                  default: `Share now and we'll send you the code`,
                },
                subtitle: {
                  type: ['null', 'string'],
                  title: 'Subtitle',
                  default: null,
                },
                titleAfterShare: {
                  type: 'string',
                  title: 'Title after share',
                  default: `Thank you for sharing with your friends`,
                },
                subtitleAfterShare: {
                  type: ['null', 'string'],
                  title: 'Subtitle after share',
                  default: `Keep an eye on your email for your code`,
                },
              },
            },
            stepsOrder: {
              type: 'array',
              title: 'Steps Order',
              default: ['COVER', 'USER_INFO_CAPTURE', 'SHARE'],
              items: {
                type: 'string',
                enum: ['COVER', 'USER_INFO_CAPTURE', 'SHARE'],
              },
            },
            heroImageOverInfo: {
              title: 'Hero Image over info',
              type: 'string',
              default: 'Get XX% off <br> your next order',
            },
            swipeInstruction: {
              title: 'Swipe instruction',
              type: 'string',
              default: 'Swipe',
            },
          },
        },
        desktop: {
          type: 'object',
          title: 'Desktop',
          properties: {
            userInfoCaptureStep: {
              type: 'object',
              title: 'User info capture step',
              properties: {
                title: {
                  title: 'Title',
                  type: 'string',
                  default: 'Get XX% off for you and your friends',
                },
                subtitle: {
                  title: 'Subtitle',
                  type: ['null', 'string'],
                  default:
                    'Enter your email to share now and receive your voucher code',
                },
                extraParagraphs: {
                  title: 'Extra Paragraphs',
                  type: ['null', 'array'],
                  items: {
                    type: 'string',
                  },
                },
              },
            },
            shareStep: {
              type: 'object',
              title: 'Share step',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title',
                  default: `Share now and we'll send you the code`,
                },
                subtitle: {
                  type: ['null', 'string'],
                  title: 'Subtitle',
                  default: `Choose how you would like to share`,
                },
                titleAfterShare: {
                  type: 'string',
                  title: 'Title after share',
                  default: `Thank you for sharing with your friends`,
                },
                subtitleAfterShare: {
                  type: ['null', 'string'],
                  title: 'Subtitle after share',
                  default: `Keep an eye on your email for your code`,
                },
              },
            },
            stepsOrder: {
              type: 'array',
              title: 'Steps Order',
              default: ['USER_INFO_CAPTURE', 'SHARE'],
              items: {
                type: 'string',
                enum: ['USER_INFO_CAPTURE', 'SHARE'],
              },
            },
            heroImageOverInfo: {
              title: 'Hero Image over info',
              type: 'string',
              default: 'Get XX% off <br> your next order',
            },
          },
        },
        mini: {
          type: 'object',
          title: 'Mini',
          properties: {
            title: {
              type: 'string',
              title: 'Title',
              default: 'Refer a friend',
            },
            toggleButtonText: {
              type: 'string',
              title: 'Toggle button text',
              default: 'Get XX% off',
            },
          },
        },
        socialShareOptions: {
          type: 'object',
          title: 'Social Share Options',
          properties: {
            FALLBACK: {
              type: 'object',
              title: 'DEFAULT',
              properties: {
                postImageUrl: {
                  type: 'string',
                  title: 'Post preview image URL',
                  format: 'CustomImageUpload',
                  default: 'https://dev-dist.soreto.com/img/preview-image.webp',
                },
                multiShare: {
                  type: 'boolean',
                  title: 'Allow multishare',
                  default: false,
                },
                preview: {
                  type: 'object',
                  title: 'Preview Options',
                  properties: {
                    title: {
                      type: 'string',
                      title: 'Title',
                      default: 'Get XX% off at [My Brand]',
                    },
                    description: {
                      type: 'string',
                      title: 'Description',
                      default:
                        'Follow my exclusive referral link to get XX% off at [My Brand]. Limited time offer.',
                    },
                    link: {
                      type: 'string',
                      title: 'Link',
                      default: 'link.com',
                    },
                    titleAfterShare: {
                      type: 'string',
                      title: 'Title after share',
                      default: 'Success!',
                    },
                    descriptionAfterShare: {
                      type: 'string',
                      title: 'Description after share',
                      default: `Thanks for inviting your friends to get XX% off. Your reward is on it's way to you.`,
                    },
                    linkAfterShare: {
                      type: ['null', 'string'],
                      title: 'Link after share',
                      default: 'Click on another icon to share again',
                    },
                    showPostImage: {
                      type: 'boolean',
                      title:
                        'Show the post image preview on the social option selection',
                      default: false,
                    },
                  },
                },
                shareButtonText: {
                  type: 'string',
                  title: 'Share button text',
                  default: 'Share Now',
                },
                afterShareButtonText: {
                  type: 'string',
                  title: 'Already shared button text',
                  default: `You've shared`,
                },
              },
            },
            FACEBOOK: {
              type: 'object',
              title: 'FACEBOOK',
              properties: {
                ...socialPlatformOption,
                ...{
                  default: {
                    type: 'boolean',
                    title: 'Default',
                    default: true,
                  },
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 1,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/facebook.svg',
                  },
                  showOn: {
                    ...showOn,
                    ...{
                      default: ['ALL'],
                    },
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      showPostImage: {
                        type: 'boolean',
                        title:
                          'Show the post image preview on the social option selection',
                        default: true,
                      },
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Facebook',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `You've shared on Facebook`,
                  },
                },
              },
            },
            WHATSAPP: {
              type: 'object',
              title: 'WHATSAPP',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 2,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/whatsapp.svg',
                  },
                  showOn: {
                    ...showOn,
                    ...{
                      default: ['ALL'],
                    },
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      showPostImage: {
                        type: 'boolean',
                        title:
                          'Show the post image preview on the social option selection',
                        default: true,
                      },
                    },
                  },
                  multiShare: {
                    type: 'boolean',
                    title: 'Allow multishare',
                    default: true,
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Whatsapp',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `Share on Whatsapp again`,
                  },
                },
              },
            },
            TWITTER: {
              type: 'object',
              title: 'TWITTER',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 3,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/x.svg',
                  },
                  showOn: {
                    ...showOn,
                    ...{
                      default: ['ALL'],
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to X',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `You've shared on X`,
                  },
                },
              },
            },
            INSTAGRAM: {
              type: 'object',
              title: 'INSTAGRAM',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 4,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/instagram.svg',
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      titleAfterShare: {
                        type: 'string',
                        title: 'Title after share',
                        default: 'Your exclusive referral link',
                      },
                      descriptionAfterShare: {
                        type: ['null', 'string'],
                        title: 'Description after share',
                        default: null,
                      },
                      linkAfterShare: {
                        type: ['null', 'string'],
                        title: 'Link after share',
                        default: null,
                      },
                      showPostImage: {
                        type: 'boolean',
                        title:
                          'Show the post image preview on the social option selection',
                        default: true,
                      },
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Instagram',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'Already shared button text',
                    default: `Copy link & share to Instagram`,
                  },
                },
              },
            },
            MESSENGER: {
              type: 'object',
              title: 'MESSENGER',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 5,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/messenger.svg',
                  },
                  showOn: {
                    ...showOn,
                    ...{
                      default: ['MOBILE'],
                    },
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      showPostImage: {
                        type: 'boolean',
                        title:
                          'Show the post image preview on the social option selection',
                        default: true,
                      },
                    },
                  },
                  multiShare: {
                    type: 'boolean',
                    title: 'Allow multishare',
                    default: true,
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Messenger',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `Share on Messenger again`,
                  },
                },
              },
            },
            PINTEREST: {
              type: 'object',
              title: 'PINTEREST',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 6,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/pinterest.svg',
                  },
                  showOn: {
                    ...showOn,
                    ...{
                      default: ['ALL'],
                    },
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      showPostImage: {
                        type: 'boolean',
                        title:
                          'Show the post image preview on the social option selection',
                        default: true,
                      },
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Pinterest',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `You've shared on Pinterest`,
                  },
                },
              },
            },
            LINK: {
              type: 'object',
              title: 'LINK',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 7,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/link.svg',
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      titleAfterShare: {
                        type: 'string',
                        title: 'Title after share',
                        default: 'Your exclusive referral link',
                      },
                      descriptionAfterShare: {
                        type: ['null', 'string'],
                        title: 'Description after share',
                        default: null,
                      },
                      linkAfterShare: {
                        type: ['null', 'string'],
                        title: 'Link after share',
                        default: null,
                      },
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to get your link',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'Already shared button text',
                    default: `Copy link`,
                  },
                },
              },
            },
            TELEGRAM: {
              type: 'object',
              title: 'TELEGRAM',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 8,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/telegram.svg',
                  },
                  multiShare: {
                    type: 'boolean',
                    title: 'Allow multishare',
                    default: true,
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Telegram',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `Share on Telegram again`,
                  },
                },
              },
            },
            SNAPCHAT: {
              type: 'object',
              title: 'SNAPCHAT',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 8,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/snapchat.svg',
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share to Snapchat',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'After Share Button text',
                    default: `You've shared on Snapchat`,
                  },
                },
              },
            },
            EMAIL: {
              type: 'object',
              title: 'EMAIL',
              properties: {
                ...socialPlatformOption,
                ...{
                  order: {
                    type: 'number',
                    title: 'Order',
                    default: 8,
                  },
                  icon: {
                    type: 'string',
                    default: 'https://dev-dist.soreto.com/img/email.svg',
                  },
                  preview: {
                    type: 'object',
                    title: 'Preview Options',
                    properties: {
                      description: {
                        title: 'Description',
                        type: 'string',
                        default: `Just click directly on "Share Via Email" below to receive an e-mail to forward to your friends.`,
                      },
                      descriptionAfterShare: {
                        type: ['null', 'string'],
                        title: 'Description after share',
                        default:
                          'An email was sent to you to forward to your friends',
                      },
                    },
                  },
                  shareButtonText: {
                    type: 'string',
                    title: 'Share Button text',
                    default: 'Share via email',
                  },
                  afterShareButtonText: {
                    type: 'string',
                    title: 'Already shared button text',
                    default: `You've shared via email`,
                  },
                },
              },
            },
          },
        },
        footer: {
          type: 'object',
          title: 'Footer text content',
          properties: {
            paragraphs: {
              type: 'array',
              title: 'Paragraphs',
              items: {
                type: 'string',
                title: 'paragraph',
              },
              default: [
                `By clicking “Share” via any of the above links, you acknowledge that you have read and agreed to Soreto's <br> <a href="https://soreto.com/terms-and-conditions">Terms and Conditions</a> and <a href="https://soreto.com/privacy-policy">Privacy and Cookies Policy</a>.`,
              ],
            },
          },
        },
        extraInfo: {
          type: 'object',
          title: 'Extra information',
          properties: {
            info_1: {
              type: 'string',
              title: 'Extra 1',
              default: '102,461 people shared last month',
            },
            info_2: {
              type: 'string',
              title: 'Extra 2',
              default: 'REFER A FRIEND',
            },
          },
        },
        customScript: {
          type: ['null', 'string'],
          title: 'Custom script',
          format: 'CustomTextArea',
        },
      },
    },
    style: {
      type: 'object',
      title: 'Styles',
      properties: {
        heroImageContainerBgColor: {
          type: ['null', 'string'],
          title: 'Hero Image Container Background color',
          format: 'CustomColorPicker',
        },
        linkColor: {
          type: ['null', 'string'],
          title: 'Link text color',
          format: 'CustomColorPicker',
          default: null,
        },
        userInfoCaptureSubmitButtonColor: {
          type: ['null', 'string'],
          title: 'User info capture submit button color',
          format: 'CustomColorPicker',
          default: null,
        },
        closeButtonIconUrl: {
          type: 'string',
          title: 'Close Button Icon Url',
          format: 'CustomImageUpload',
          default: 'https://dev-dist.soreto.com/img/close.svg',
        },
        minimizeButtonIconUrl: {
          type: 'string',
          title: 'Minimize Button Icon Url',
          format: 'CustomImageUpload',
          default: 'https://dev-dist.soreto.com/img/dash.svg',
        },
        desktop: {
          type: 'object',
          title: 'Desktop',
          properties: {
            primaryColor: {
              type: ['null', 'string'],
              title: 'Primary text color',
              format: 'CustomColorPicker',
            },
            heroImageContainerBgColor: {
              type: ['null', 'string'],
              title: 'Hero Image Container Background color',
              format: 'CustomColorPicker',
            },
            descritiveContainerBgColor: {
              type: ['null', 'string'],
              title: 'Instructions Container Background Color',
              format: 'CustomColorPicker',
            },
            heroImageUrl: {
              type: ['null', 'string'],
              title: 'Hero Image Url',
              format: 'CustomImageUpload',
              default:
                'https://dev-dist.soreto.com/img/default-background-image-3.webp',
            },
            heroImageContainerPadding: {
              type: ['null', 'string'],
              title: 'Hero Image Container Padding',
            },
            input: inputStyles,
            socialShareOptions: {
              type: 'object',
              title: 'Social Share Options',
              properties: {
                socialOptionsInactiveColor: {
                  type: ['null', 'string'],
                  title: 'Color of the social icon when not active',
                  format: 'CustomColorPicker',
                },
              },
            },
            shareStep: {
              type: 'object',
              title: 'Share Step',
              properties: {
                sharePreviewSelectedPlatformBorderColor: {
                  type: ['null', 'string'],
                  title: 'Selected platform border color',
                  format: 'CustomColorPicker',
                },
              },
            },
          },
        },
        mobile: {
          type: 'object',
          title: 'Mobile',
          properties: {
            primaryColor: {
              type: ['null', 'string'],
              title: 'Primary text color',
              format: 'CustomColorPicker',
            },
            containerBgColor: {
              type: ['null', 'string'],
              title: 'Container Background Color',
              format: 'CustomColorPicker',
            },
            coverImageUrl: {
              type: ['null', 'string'],
              title: 'Cover Image URL',
              format: 'CustomImageUpload',
              default:
                'https://dev-dist.soreto.com/img/default-background-image-3.webp',
            },
            heroImageUrl: {
              type: ['null', 'string'],
              title: 'Hero Image URL',
              format: 'CustomImageUpload',
              default:
                'https://dev-dist.soreto.com/img/default-background-image-3.webp',
            },
            coverContainerPadding: {
              type: ['null', 'string'],
              title: 'Cover container padding',
              default: '10px',
            },
            showStepDots: {
              type: 'boolean',
              title: 'Display navigation dots',
              default: true,
            },
            socialShareOptions: {
              type: 'object',
              title: 'Social Share Options',
              properties: {
                socialOptionsInactiveColor: {
                  type: ['null', 'string'],
                  title: 'Color of the social icon when not active',
                  format: 'CustomColorPicker',
                },
              },
            },
            shareStep: {
              type: 'object',
              title: 'Share Step',
              properties: {
                showHeroImage: {
                  type: 'boolean',
                  title: 'Show hero image',
                  default: false,
                },
              },
            },
          },
        },
        shareStep: {
          type: 'object',
          title: 'Share Step',
          properties: {
            sharePreviewSelectedPlatformBorderColor: {
              type: ['null', 'string'],
              title: 'Selected platform border color',
              format: 'CustomColorPicker',
            },
          },
        },
        mini: {
          type: 'object',
          title: 'Mini',
          properties: {
            coverImageUrl: {
              type: ['null', 'string'],
              title: 'Cover image Url',
              format: 'CustomImageUpload',
              default:
                'https://dev-dist.soreto.com/img/default-background-image-3.webp',
            },
            backgroundColor: {
              type: 'string',
              title: 'Background color',
              format: 'CustomColorPicker',
            },
            color: {
              type: 'string',
              title: 'Color',
              format: 'CustomColorPicker',
            },
            toggleButton: {
              type: 'object',
              title: 'Toggle button',
              properties: {
                backgroundColor: {
                  type: 'string',
                  title: 'Background color',
                  format: 'CustomColorPicker',
                },
                color: {
                  type: 'string',
                  title: 'Color',
                  format: 'CustomColorPicker',
                },
              },
            },
          },
        },
        input: inputStyles,
        customFonts: {
          type: 'object',
          title: 'Custom fonts',
          properties: {
            customFont1: {
              type: ['string', 'null'],
              title: 'Custom font 1 (custom-font-1)',
            },
            customFont2: {
              type: ['string', 'null'],
              title: 'Custom font 2 (custom-font-2)',
            },
            customFont3: {
              type: ['string', 'null'],
              title: 'Custom font 3 (custom-font-3)',
            },
            customFontFace1: {
              type: 'object',
              title: 'Custom font face 1',
              properties: {
                url: {
                  title: 'Url',
                  type: ['string', 'null'],
                },
                fontFamily: {
                  title: 'Font family',
                  type: ['string', 'null'],
                },
              },
            },
            customFontFace2: {
              type: 'object',
              title: 'Custom font face 2',
              properties: {
                url: {
                  title: 'Url',
                  type: ['string', 'null'],
                },
                fontFamily: {
                  title: 'Font family',
                  type: ['string', 'null'],
                },
              },
            },
            customFontFace3: {
              type: 'object',
              title: 'Custom font face 3',
              properties: {
                url: {
                  title: 'Url',
                  type: ['string', 'null'],
                },
                fontFamily: {
                  title: 'Font family',
                  type: ['string', 'null'],
                },
              },
            },
          },
        },
      },
    },
  },
};

export default schema;
