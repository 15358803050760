import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LightboxIframe from './lightboxIframe';

// Connect to the channel named "my_bus".
const channel = new BroadcastChannel('asset_change');

function Preview() {
  const [asset, setAsset] = useState<any>();
  const { id } = useParams();

  // useEffect(() => {}, []);
  channel.onmessage = function (e) {
    if (e.data._id === id) {
      setAsset(e.data);
    }
  };

  return (
    <div>
      {!asset && <div>offline</div>}
      {asset && (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            borderStyle: 'solid',
            borderWidth: '1px',
            width: 'fit-content',
          }}
        >
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              height: 'fit-content',
            }}
          >
            <LightboxIframe blob={asset.compiled} width={800} height={500} />
          </div>
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              height: 'fit-content',
            }}
          >
            <LightboxIframe blob={asset.compiled} width={305} height={580} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Preview;
