import React, { useEffect, useRef } from 'react';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { Card } from 'primereact/card';

interface JSONEditorComponentProps {
  key: string;
  jsonData: any;
  onChangeData: (value: string) => void;
}

const JSONEditorComponent: React.FC<JSONEditorComponentProps> = ({
  key,
  jsonData,
  onChangeData,
}) => {
  // DOM reference
  const containerRef = useRef<HTMLDivElement | null>(null);
  let jsonEditor: JSONEditor | null = null;

  /**
   * ON LOAD
   */
  useEffect(() => {
    if (containerRef.current) {
      const options: JSONEditorOptions = {
        modes: ['tree', 'code'],
        onChangeJSON: (json: any) => {
          /**
           * Callback on change
           */
          onChangeData(json);
        },
        onChange: () => {
          /**
           * Callback on change
           */
          try {
            const text = jsonEditor?.getText();

            if (!text) return;

            onChangeData(JSON.parse(text));
          } catch (error) {
            console.warn(error);
          }
        },
      };

      // instantiate the editor
      jsonEditor = new JSONEditor(containerRef.current, options);
      jsonEditor.set(jsonData);

      // programatically expand all the nodes
      jsonEditor.expandAll();
    }

    return () => {
      if (jsonEditor) {
        jsonEditor.destroy();
      }
    };
  }, []);

  return (
    <Card key={key} style={{ height: '100vh' }}>
      <div ref={containerRef} style={{ width: '100%', height: '80vh' }} />
    </Card>
  );
};

export default JSONEditorComponent;
