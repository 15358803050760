// ____  ____  ____   __      ____   __    ___  ____
// (  _ \( ___)(_  _) /__\    (  _ \ /__\  / __)( ___)
//  ) _ < )__)   )(  /(__)\    )___//(__)\( (_-. )__)
// (____/(____) (__)(__)(__)  (__) (__)(__)\___/(____)
//

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-debugger */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-extraneous-dependencies */

import { useState, useEffect, useRef, createRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import _ from 'lodash';

import Form from '@rjsf/material-ui';
import {
  RegistryWidgetsType,
  WidgetProps,
  UiSchema,
  FieldTemplateProps,
} from '@rjsf/utils';
import { InputSwitch } from 'primereact/inputswitch';
import validator from '@rjsf/validator-ajv8';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';

import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import JSONEditorComponent from '../../components/JSONEditor';
import AssetService from '../../services/AssetService';
import LightboxIframe from './lightboxIframe';
import './edit.scss';
import Upload from '../../components/uploadComponent/Upload';
import { css } from './cssProps';
import schema from './sharePlaceSchemaV1';

const channel = new BroadcastChannel('asset_change');

const CustomColorPicker = ({ id, value, label, onChange }: WidgetProps) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <label htmlFor={id}>{label}</label>
    <div style={{ display: 'flex' }}>
      {value ? (
        <input
          type="color"
          id={id}
          value={value}
          onChange={event => onChange(event.target.value)}
          style={{ flex: 6 }}
        />
      ) : (
        <Button
          type="button"
          onClick={() => onChange('#FFFFFF')}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flex: 6,
            backgroundColor: 'gray',
          }}
        >
          select
        </Button>
      )}

      <Button
        type="button"
        onClick={() => onChange(null)}
        style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
      >
        X
      </Button>
    </div>
  </div>
);

const CustomTextArea = ({ id, value, label, onChange }: WidgetProps) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <label htmlFor={id}>{label}</label>
    <div style={{ display: 'flex' }}>
      <InputTextarea
        value={value}
        onChange={e => onChange(e.target?.value)}
        style={{ width: '100%' }}
      />
    </div>
  </div>
);

let _campaignVersionId: string | undefined;

const CustomImageUpload = ({
  id,
  value,
  label,
  name,
  onChange,
}: WidgetProps) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <label htmlFor={id}>{label}</label>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 8 }}>
        {value ? (
          <Upload
            friendlyName={label}
            asset={name}
            changedProperty={name}
            src={value}
            isActive
            onUpload={(event: any) => onChange(event.url)}
            section={`dist.soreto.com/clientsrc/assets_auto/lightbox/${_campaignVersionId}`}
          />
        ) : (
          <Button
            type="button"
            onClick={() => onChange('#FFFFFF')}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 6,
              backgroundColor: 'gray',
            }}
          >
            select
          </Button>
        )}
      </div>

      <Button
        type="button"
        onClick={() => onChange(null)}
        style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
      >
        X
      </Button>
    </div>
  </div>
);

const CustomContainer = ({ children }: WidgetProps) => {
  return <div style={{ backgroundColor: 'lime' }}>{children}</div>;
};

const CustomFieldTemplate = (props: FieldTemplateProps): any => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    schema: ch,
  } = props;

  if (ch.type === 'object') {
    return (
      <Accordion>
        <AccordionTab header={label}>
          <div className={classNames}>
            {/* <label htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label> */}
            {/* {description} */}
            {children}
            {errors}
            {help}
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  return (
    <div className={classNames}>
      {/* <label htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label> */}
      {/* {description} */}
      {children}
      {errors}
      {help}
    </div>
  );
};

const widgets: RegistryWidgetsType = {
  CustomColorPicker,
  CustomContainer,
  CustomImageUpload,
  CustomTextArea,
};

const uiSchema: UiSchema = {
  content: {
    'ui:widget': CustomContainer,
  },
};

let _asset: any = {};
let _buildHelper = {};
let _extraStyle = {};
let _lastSavedAsset: any = {};
let _lastSavedTemplate = '';
let _lastSavedTemplateVersion = '';
let _lastSavedExtraStyle = '';
let _selectedTemplate = '';
let _selectedVersion = '';

const Edit = () => {
  const [asset, setAsset] = useState<any>({});
  const [compiled, setCompiled] = useState('');
  const [buildHelper, setBuildHelper] = useState<any>({
    mobile: {
      showSteps: {
        cover: true,
        userInfoCapture: true,
        share: true,
      },
    },
  });
  const [extraStyle, setExtraStyle] = useState<any>({});
  const [selectedExtraStyle, setSelectedExtraStyle] = useState<any>({
    attrRef: null,
    contained: null,
  });
  const [selectedCssProperty, setSelectedCssProperty] = useState<any>('');
  const [selectedCssPropertyValue, setSelectedCssPropertyValue] =
    useState<any>('');
  const [
    selectedCssPropertyValueSugestions,
    setSelectedCssPropertyValueSugestions,
  ] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>('');
  const [selectedVersion, setSelectedVersion] = useState<any>('');
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [editMode, setEditMOde] = useState<any>(false);
  const [showMinimized, setShowMinimized] = useState<any>(false);
  const [showRawDataEditor, setShowRawDataEditor] = useState<any>(false);
  const [showRawCssEditor, setShowRawCssEditor] = useState<any>(false);
  const [externalPreview, setExternalPreview] = useState<any>(false);
  const autoCompleteRef = createRef();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  useEffect(() => {
    if (!id) return;

    window.addEventListener('keydown', async event => {
      if (event.ctrlKey || event.metaKey) {
        switch (String.fromCharCode(event.which).toLowerCase()) {
          case 's':
            event.preventDefault();

            await compile(false);

            break;
        }
      }
    });

    window.addEventListener(
      'message',
      function (e) {
        try {
          if (typeof e.data !== 'string') return;

          const parsedData = JSON.parse(e.data);

          if (parsedData.spotElementClick) {
            setSelectedExtraStyle({
              attrRef: parsedData.spotElementClick,
              contained: parsedData.contained,
            });
          } else {
            const assetCopy = JSON.parse(JSON.stringify(_asset));

            _.set(assetCopy, parsedData.schemaProp, parsedData.valueChange);

            setAsset(assetCopy);

            compile(false);
          }
        } catch (error) {
          console.error(error);
        }
      },
      false,
    );

    if (id === 'search') {
      const cpvId = searchParams.get('cpv_id');
      if (cpvId) {
        toast.current?.show({
          summary: 'Loading',
          detail: `Searching your asset based on the Campaign Version.`,
          severity: 'info',
          sticky: true,
        });
        AssetService.getAssetByCampaignVersionId(cpvId)
          .then(result => {
            navigate(`/asset/${result.data?.resultData._id}`, {
              replace: true,
            });

            setAsset(result.data?.resultData);
            setExtraStyle(result.data?.resultData.extraStyle);
            setSelectedTemplate(result.data?.resultData.templateName);
            setSelectedVersion(result.data?.resultData.templateVersion);
            _lastSavedAsset = result.data?.resultData;

            toast?.current?.clear();
            toast.current?.show({
              summary: 'Loaded!',
              detail: `You can start editing your asset`,
              severity: 'info',
            });
          })
          .catch((error: any) => {
            toast?.current?.clear();

            if (error.response?.status === 404) {
              toast.current?.show({
                summary: 'New asset',
                detail: `Submit the form to create the asset and start editing`,
                severity: 'info',
                life: 10000,
              });

              const assetCopy = JSON.parse(JSON.stringify(_asset));
              assetCopy.campaignVersionId = cpvId;

              setAsset(assetCopy);
            } else {
              console.error(error);
            }
          });
      }

      return;
    }

    AssetService.getAsset(id)
      .then((result: any) => {
        setAsset(result.data?.resultData);
        setExtraStyle(result.data?.resultData.extraStyle);
        setSelectedTemplate(result.data?.resultData.templateName);
        setSelectedVersion(result.data?.resultData.templateVersion);
        _lastSavedAsset = result.data?.resultData;
        _lastSavedTemplate = result.data?.resultData.templateName;
        _lastSavedTemplateVersion = result.data?.resultData.templateVersion;
        _lastSavedExtraStyle = result.data?.resultData.extraStyle;
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (toast.current && _lastSavedAsset && _lastSavedAsset.data) {
      if (
        !isSameObject(_lastSavedAsset, asset) ||
        selectedTemplate !== _lastSavedTemplate ||
        selectedVersion !== _lastSavedTemplateVersion ||
        !isSameObject(extraStyle, _lastSavedExtraStyle)
      ) {
        if (toast.current.state.messages.length === 1) {
          return;
        }
        toast.current.show({
          severity: 'warn',
          summary: 'Change captured',
          detail: 'The data has changed but no applied.',
          sticky: true,
        });
      } else {
        toast.current.clear();
      }
    }

    _campaignVersionId = asset.campaignVersionId;
  }, [asset, selectedTemplate]);

  useEffect(() => {
    compile(false).finally(() => {
      console.log('');
    });
  }, [editMode, showMinimized, buildHelper]);

  _asset = asset;
  _buildHelper = buildHelper;
  _extraStyle = extraStyle;
  _selectedTemplate = selectedTemplate;
  _selectedVersion = selectedVersion;

  let selectedExtraStyleKey: any = null;
  const existingSingleAttrStyle = extraStyle[selectedExtraStyle.attrRef];

  if (selectedExtraStyle.contained && !existingSingleAttrStyle) {
    selectedExtraStyleKey = `${selectedExtraStyle.contained}-IN-${selectedExtraStyle.attrRef}`;
  } else {
    selectedExtraStyleKey = selectedExtraStyle.attrRef;
  }

  const compile = async (persist: boolean) => {
    try {
      if (!_selectedTemplate || !_selectedVersion) {
        toast.current?.show({
          summary: 'Required fields',
          detail: `You must select the template and the version.`,
          severity: 'warn',
          sticky: true,
        });

        return;
      }

      replaceUndefinedToNull(_asset.data);

      const compiledResult = await AssetService.compileAsset({
        ..._asset,
        ...{ buildHelper: _buildHelper },
        ...{ extraStyle: _extraStyle },
        ...{ persist },
        ...{
          templateName: _selectedTemplate,
          templateVersion: _selectedVersion,
        },
      });

      setCompiled(compiledResult.data.resultData.compiled);
      setAsset(compiledResult.data.resultData);

      _lastSavedAsset = _asset;
      _lastSavedTemplate = _selectedTemplate;

      if (toast && toast.current) {
        toast.current.clear();
      }

      channel.postMessage(_lastSavedAsset);

      if (persist) {
        toast.current?.show({
          summary: 'Persisted!',
          detail: 'Succesfully saved!',
          severity: 'success',
        });

        navigate(`/asset/${compiledResult.data.resultData._id}`, {
          replace: true,
        });
      }
    } catch (error: any) {
      toast.current?.show({
        summary: 'ERROR saving the asset',
        detail: JSON.stringify(error?.message),
        severity: 'error',
        sticky: true,
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            height: '35px',
            marginBottom: '10px',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <label htmlFor="templateDropdown">Template: </label>
          <Dropdown
            id="templateDropdown"
            options={['tpl-1', 'tpl-2', 'tpl-3']}
            onChange={e => {
              setSelectedTemplate(e.value);
            }}
            value={selectedTemplate}
          />
          <label htmlFor="versionDropdown">Version: </label>
          <Dropdown
            id="versionDropdown"
            options={['1']}
            onChange={e => {
              setSelectedVersion(e.value);
            }}
            value={selectedVersion}
          />
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={() => {
              setExternalPreview(!externalPreview);
              window.open(`/assetPreview/${id}`, '_blank');
            }}
            style={{
              width: '5px',
              height: '5px',
              top: '0px',
              right: '0px',
              zIndex: '999',
              padding: '0px',
              minWidth: '5px'
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Toast ref={toast} />
        <div style={{ flex: 1 }}>
          <div style={{ height: '100vh', overflow: 'scroll' }}>
            <Form
              schema={schema}
              validator={validator}
              formData={asset.data}
              widgets={widgets}
              uiSchema={uiSchema}
              templates={{ FieldTemplate: CustomFieldTemplate }}
              onChange={(e: any, isd: any) => {
                const assetCopy = JSON.parse(JSON.stringify(asset));
                assetCopy.data = e.formData;

                setAsset(assetCopy);
              }}
              onSubmit={async () => {
                compile(true);
              }}
            >
              <Button
                type="submit"
                icon="pi pi-check"
                style={{
                  position: 'fixed',
                  bottom: '10px',
                  left: '10px',
                  zIndex: 999,
                }}
              />
            </Form>
            <Button
              className="p-button-secondary"
              icon="pi pi-code"
              onClick={() => setShowRawDataEditor(true)}
            />
          </div>
        </div>
        <div
          style={{
            flex: 3,
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
            // position: 'sticky',
            // top: 0,
          }}
        >
          <div
            style={{
              display: !externalPreview ? 'flex': 'none',
              gap: '5px',
              borderStyle: 'solid',
              borderWidth: '1px',
              width: 'fit-content',
            }}
          >
            <div
              style={{
                borderStyle: 'solid',
                borderWidth: '1px',
                height: 'fit-content',
              }}
            >
              <LightboxIframe
                blob={compiled || asset.compiled}
                width={800}
                height={500}
              />
            </div>
            <div
              style={{
                borderStyle: 'solid',
                borderWidth: '1px',
                height: 'fit-content',
              }}
            >
              <LightboxIframe
                blob={compiled || asset.compiled}
                width={305}
                height={580}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              boxShadow: 'revert',
              borderRadius: '15px',
              backgroundColor: 'gainsboro',
              padding: '10px',
            }}
          >
            <div
              style={{
                flex: 3,
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              <label htmlFor="cbxCover">Cover</label>
              <InputSwitch
                id="cbxCover"
                checked={buildHelper.mobile.showSteps.cover}
                onChange={e => {
                  const buildHelperCopy = JSON.parse(
                    JSON.stringify(buildHelper),
                  );
                  buildHelperCopy.mobile.showSteps.cover = e.value;

                  setBuildHelper(buildHelperCopy);
                }}
              />
              <label htmlFor="cbxUserInfoCapture">User Info Capture</label>
              <InputSwitch
                id="cbxUserInfoCapture"
                checked={buildHelper.mobile.showSteps.userInfoCapture}
                onChange={e => {
                  const buildHelperCopy = JSON.parse(
                    JSON.stringify(buildHelper),
                  );
                  buildHelperCopy.mobile.showSteps.userInfoCapture = e.value;

                  setBuildHelper(buildHelperCopy);
                }}
              />
              <label htmlFor="cbxShare">Share</label>
              <InputSwitch
                id="cbxShare"
                checked={buildHelper.mobile.showSteps.share}
                onChange={e => {
                  const buildHelperCopy = JSON.parse(
                    JSON.stringify(buildHelper),
                  );
                  buildHelperCopy.mobile.showSteps.share = e.value;

                  setBuildHelper(buildHelperCopy);
                }}
              />
            </div>
            <div
              style={{
                flex: 3,
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              <label htmlFor="cbxSharedMode">Shared Mode</label>
              <InputSwitch
                id="cbxSharedMode"
                checked={buildHelper.sharedMode}
                onChange={e => {
                  const buildHelperCopy = JSON.parse(
                    JSON.stringify(buildHelper),
                  );
                  buildHelperCopy.sharedMode = e.value;

                  setBuildHelper(buildHelperCopy);
                }}
              />
              <label htmlFor="cbxShowMinimized">Show minimized</label>
              <InputSwitch
                id="cbxShowMinimized"
                checked={showMinimized}
                onChange={e => {
                  setShowMinimized(e.value);
                }}
              />
            </div>
            <div
              style={{
                flex: 3,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              <label htmlFor="cbxEditMode">Edit mode</label>
              <InputSwitch
                id="cbxEditMode"
                checked={editMode}
                onChange={async (e: any) => {
                  const buildHelperCopy = JSON.parse(
                    JSON.stringify(buildHelper),
                  );
                  buildHelperCopy.editMode = !editMode;

                  setEditMOde(!editMode);

                  setBuildHelper(buildHelperCopy);
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: editMode ? 'flex' : 'none',
              flexDirection: 'column',
              marginTop: '10px',
              backgroundColor: 'gainsboro',
              borderRadius: '15px',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                justifyContent: 'center',
              }}
            >
              <h2 style={{ margin: '0px' }}>Extra styles</h2>
              <Button
                className="p-button-secondary"
                icon="pi pi-code"
                onClick={() => setShowRawCssEditor(true)}
              />
            </div>
            <h4 style={{ color: 'black' }}>
              <span
                style={{
                  color: 'white',
                  backgroundColor: 'darkgray',
                  padding: '4px',
                  borderRadius: '3px',
                  display: selectedExtraStyle.attrRef ? 'initial' : 'none',
                }}
              >
                {`[${selectedExtraStyle.contained}]`}
                <br />

                <span
                  style={{ color: 'lemonchiffon', backgroundColor: 'orange' }}
                >{`[${selectedExtraStyle.attrRef}]`}</span>
              </span>
            </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
              }}
            >
              {extraStyle &&
                extraStyle[selectedExtraStyleKey] &&
                Object.keys(extraStyle[selectedExtraStyleKey]).map(key => (
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                      marginBottom: '5px',
                      backgroundColor: 'lightgray',
                      width: 'fit-content',
                      borderRadius: '5px',
                      padding: '2px',
                    }}
                  >
                    <InputText value={key} />
                    <InputText
                      value={extraStyle[selectedExtraStyleKey][key]}
                      disabled
                    />
                    <Button
                      type="button"
                      icon="pi pi-pencil"
                      style={{ display: 'flex' }}
                      onClick={() => {
                        setSelectedCssProperty(key);
                        setSelectedCssPropertyValue(
                          extraStyle[selectedExtraStyleKey][key],
                        );
                      }}
                    />
                    <Button
                      type="button"
                      label="X"
                      style={{ display: 'flex' }}
                      onClick={() => {
                        const extraStyleCopy = JSON.parse(
                          JSON.stringify(extraStyle),
                        );
                        delete extraStyleCopy[selectedExtraStyleKey][key];
                        setExtraStyle(extraStyleCopy);
                      }}
                    />
                  </div>
                ))}
            </div>

            {selectedExtraStyleKey && (
              <div style={{ display: 'flex', gap: '15px' }}>
                <Dropdown
                  filter
                  showClear
                  filterBy="name"
                  id="newCss"
                  value={selectedCssProperty}
                  onChange={e => {
                    setSelectedCssProperty(e.value);

                    if (css[e.value]) {
                      setSelectedCssPropertyValueSugestions(
                        css[e.value].values,
                      );
                    } else {
                      setSelectedCssPropertyValueSugestions([]);
                    }
                    setSelectedCssPropertyValue('');
                  }}
                  options={Object.keys(css).map(key => {
                    return { name: key, value: key };
                  })}
                  optionLabel="name"
                  style={{ flex: 5 }}
                />
                <AutoComplete
                  value={selectedCssPropertyValue}
                  style={{ flex: 5 }}
                  onChange={e => {
                    setSelectedCssPropertyValue(e.target.value);
                  }}
                  suggestions={selectedCssPropertyValueSugestions}
                  dropdown
                  completeMethod={() => {
                    console.log(css[selectedCssProperty]);
                    if (css[selectedCssProperty]) {
                      if (selectedCssPropertyValue) {
                        setSelectedCssPropertyValueSugestions(
                          (css[selectedCssProperty] as any).values
                            .filter((v: string) =>
                              v.startsWith(selectedCssPropertyValue),
                            )
                            .concat(css[selectedCssProperty].values),
                        );
                      } else {
                        setSelectedCssPropertyValueSugestions(
                          css[selectedCssProperty].values.filter((i: any) => i),
                        );
                      }
                    }
                  }}
                />
                <Button
                  type="button"
                  label="+"
                  onClick={() => {
                    const extraStyleCopy = JSON.parse(
                      JSON.stringify(extraStyle || {}),
                    );

                    if (!extraStyleCopy[selectedExtraStyleKey]) {
                      extraStyleCopy[selectedExtraStyleKey] = {};
                    }

                    extraStyleCopy[selectedExtraStyleKey][selectedCssProperty] =
                      selectedCssPropertyValue;

                    setExtraStyle(extraStyleCopy);
                  }}
                  style={{ flex: 1 }}
                />
              </div>
            )}
          </div>

          <div
            style={{
              display: editMode ? 'flex' : 'none',
              flexDirection: 'column',
              marginTop: '10px',
              backgroundColor: 'gainsboro',
              borderRadius: '15px',
              padding: '10px',
              alignItems: 'center',
            }}
          >
            <h2>Other Styles</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                width: '100%',
              }}
            >
              {extraStyle &&
                Object.keys(extraStyle).map(eg => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    style={{
                      marginBottom: '15px',
                      backgroundColor: 'gray',
                      padding: '10px',
                      cursor: 'pointer',
                      border: 'solid 1px',
                    }}
                    onClick={() => {
                      if (eg.includes('-IN-')) {
                        const parts = eg.split('-IN-');

                        setSelectedExtraStyle({
                          attrRef: parts[1],
                          contained: parts[0],
                        });
                      } else {
                        setSelectedExtraStyle({ attrRef: eg });
                      }
                    }}
                  >
                    <h4 style={{ color: 'black' }}>
                      {eg.includes('-IN-') ? (
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: 'darkgray',
                            padding: '4px',
                            borderRadius: '3px',
                          }}
                        >
                          {`[${eg.split('-IN-')[0]}]`}
                          <br />

                          <span
                            style={{
                              color: 'lemonchiffon',
                              backgroundColor: 'orange',
                            }}
                          >{`[${eg.split('-IN-')[1]}]`}</span>
                        </span>
                      ) : (
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: 'darkgray',
                            padding: '4px',
                            borderRadius: '3px',
                          }}
                        >
                          {`[${eg}]`}
                        </span>
                      )}
                    </h4>
                    {Object.keys(extraStyle[eg]).map(key => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions

                      <div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          <InputText value={key} disabled />
                          <InputText value={extraStyle[eg][key]} disabled />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>

          {/* <div style={{ flex: 1 }}>{JSON.stringify(asset?.data?.style)}</div> */}
        </div>
      </div>
      {showMinimized && (
        <div style={{ position: 'fixed', bottom: '0px', right: '0px' }}>
          <LightboxIframe
            blob={compiled || asset.compiled}
            width={350}
            height={290}
          />
        </div>
      )}

      <Sidebar
        visible={showRawDataEditor}
        position="left"
        onHide={() => setShowRawDataEditor(false)}
        style={{ width: '40%', backgroundColor: 'gray', color: 'white' }}
      >
        <h2>Raw Data</h2>
        <JSONEditorComponent
          key="dataJsonEdition"
          jsonData={asset.data}
          onChangeData={(change: string) => {
            setAsset({ ...asset, data: change });
          }}
        />
      </Sidebar>

      <Sidebar
        visible={showRawCssEditor}
        position="bottom"
        onHide={() => setShowRawCssEditor(false)}
        style={{
          width: '100%',
          height: '50vh',
          backgroundColor: 'orange',
          color: 'black',
        }}
      >
        <h2>Raw Custom css</h2>
        <JSONEditorComponent
          key="extraStyleJsonEdition"
          jsonData={extraStyle}
          onChangeData={(change: string) => {
            setExtraStyle(change);
          }}
        />
      </Sidebar>
    </div>
  );
};

export default Edit;

const replaceUndefinedToNull = (obj: any) => {
  if (!obj) return;

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = null;
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceUndefinedToNull(obj[key]);
    }
  });
};

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

const isSameObject = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isSameObject(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};
